import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
const apiEndpoint = 'https://api.smartbdshop.com/order';

const Vof = ({ products, districtsData }) => {
    const { slug } = useParams();

    // Find the product by the slug (instead of id)
    const product = products.find(product => product.slug === slug);

    if (!product) {
        return <div>Product not found</div>;
    }
    return (
        <div className="bg-gray-50">
            {/* Feature Image Section */}
            <section className="relative flex justify-center items-center h-screen bg-black">
                <img
                    className="w-full h-full object-cover"
                    src={product.feature_image}
                    alt={product.title}
                />
                <div className="absolute bottom-12 text-white text-center">
                    <h1 className="text-6xl font-bold mb-4">{product.title}</h1>
                    <p className="text-2xl">{product.description}</p>
                </div>
            </section>

            {/* Product Information Section */}
            <section className="py-16 px-8">
                <div className="text-center">
                    <p className="text-gray-500 text-sm uppercase">{product.category}</p>
                    <h2 className="text-4xl font-bold my-4">{product.title}</h2>
                    <p className="text-xl">{product.meta_description}</p>
                </div>
            </section>

            {/* VOF Landing Page Sections */}
            {product.vof_landing_page_sections.map((section, index) => (
                <section key={index} className="py-12 px-8">
                    <div className="grid md:grid-cols-2 gap-8">
                        <div className="flex flex-col justify-center">
                            <h3 className="text-3xl font-bold mb-4">{section.section}</h3>
                            <p className="text-lg">{section.content}</p>
                        </div>
                        <div>
                            <img
                                className="w-full h-auto rounded-lg shadow-lg"
                                src={section.image}
                                alt={`${product.title} - ${section.section}`}
                            />
                        </div>
                    </div>
                </section>
            ))}

            {/* Gallery Section */}
            <section className="py-16 px-8 bg-gray-100">
                <h3 className="text-3xl font-bold text-center mb-8">Gallery</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {product.alternate_images.map((image, index) => (
                        <img
                            key={index}
                            className="w-full h-auto rounded-lg shadow-md"
                            src={image}
                            alt={`Gallery ${index + 1}`}
                        />
                    ))}
                </div>
            </section>

            <CheckoutForm product={product} districtsData={districtsData} />

            {/* Footer Section */}
            <footer className="py-8 bg-gray-900 text-white text-center">
                <p className="text-lg">© 2024 {product.brand}. All rights reserved.</p>
            </footer>
        </div>
    );
};

const CheckoutForm = ({ productTitle, districtsData, product }) => {
    const [selectedVariant, setSelectedVariant] = useState('');
    const [districts] = useState(Object.keys(districtsData));
    const [thanas, setThanas] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        address: '',
        district: '',
        thana: '',
        sku: ''
    });

    // Handle district change and update Thanas dropdown
    const handleDistrictChange = (event) => {
        const selectedDistrict = event.target.value;
        setFormData({ ...formData, district: selectedDistrict, thana: '' });
        setThanas(districtsData[selectedDistrict] || []);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleVariantSelect = (sku) => {
        setFormData((prevData) => ({ ...prevData, sku: sku }));
        setSelectedVariant(sku);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Push form data to Google Spreadsheet
        console.log('Submitted Data:', formData);
        await submitToGoogleSheets(formData);
    };

    // Submit to Google Sheets using an API endpoint (example shown below)
    const submitToGoogleSheets = async (formData) => {
        // Example fetch POST request to Google Sheets API
        try {
            const response = await axios.post(apiEndpoint, formData);
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error posting data:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className="checkout-form container mx-auto p-8">
            <h2 className="text-3xl font-bold mb-6">Checkout for {productTitle}</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2">Phone</label>
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2">Address</label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2">District</label>
                    <select
                        name="district"
                        value={formData.district}
                        onChange={handleDistrictChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    >
                        <option value="">Select District</option>
                        {districts.map((district, index) => (
                            <option key={index} value={district}>
                                {district}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2">Thana</label>
                    <select
                        name="thana"
                        value={formData.thana}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    >
                        <option value="">Select Thana</option>
                        {thanas.map((thana, index) => (
                            <option key={index} value={thana}>
                                {thana}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex flex-wrap justify-center mb-4">
                    {product.variations.map((variant, index) => (
                        <div key={index} className="m-2" onClick={() => handleVariantSelect(variant.sku)}>
                            <img
                                src={variant.image}
                                alt={variant.name}
                                className={`w-32 h-32 object-cover border ${selectedVariant === variant.sku ? 'border-blue-500' : 'border-transparent'}`}
                            />
                            <p className="text-center mt-2">{variant.name}</p>
                        </div>
                    ))}
                </div>

                <button type="submit" className="w-full bg-blue-600 text-white p-3 rounded-md">
                    Submit Order
                </button>
            </form>
        </div>
    );
};

export default Vof;
