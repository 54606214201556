export const products = [
  {
    "title": "Wireless Bluetooth Headphones",
    "description": "Experience the ultimate wireless freedom with these noise-canceling Bluetooth headphones. Enjoy crystal-clear audio and an immersive sound experience.",
    "category": "Electronics",
    "tags": ["headphones", "bluetooth", "wireless", "noise-canceling"],
    "feature_image": "https://via.placeholder.com/150",
    "alternate_images": [
      "https://via.placeholder.com/150",
      "https://via.placeholder.com/150"
    ],
    "focus_keyphrase": "wireless bluetooth headphones",
    "slug": "wireless-bluetooth-headphones",
    "meta_description": "Discover the best noise-canceling wireless Bluetooth headphones for seamless music enjoyment.",
    "vof_landing_page_sections": [
      {
        "section": "overview",
        "content": "These wireless Bluetooth headphones offer superior sound quality, extended battery life, and comfortable fit for prolonged use.",
        "image": "https://via.placeholder.com/150"
      },
      {
        "section": "features",
        "content": "Noise-canceling, Bluetooth 5.0, 30-hour battery life, lightweight design, and over-ear fit.",
        "image": "https://via.placeholder.com/150"
      },
      {
        "section": "specifications",
        "content": "Bluetooth version: 5.0, Battery life: 30 hours, Charging time: 2 hours, Weight: 250g, Noise-cancellation: Active.",
        "image": "https://via.placeholder.com/150"
      }
    ],
    "brand": "AudioTech",
    "stock_status": "in_stock",
    "weight": "250g",
    "dimensions": "20 x 18 x 5 cm",
    "variations": [
      {
        "sku": "WBH-001",
        "name": "Variant 1",
        "image": 'https://via.placeholder.com/150',
        "regular_price": 120,
        "discount_price": 99,
        "stock_status": "in_stock"
      },
      {
        "sku": "WBH-002",
        "name": "Variant 2",
        "image": 'https://via.placeholder.com/150',
        "regular_price": 150,
        "discount_price": 129,
        "stock_status": "out_of_stock"
      }
    ]
  },
  {
    "title": "Smart Fitness Watch",
    "description": "Track your fitness levels, monitor your heart rate, and stay connected with this sleek smart fitness watch designed for the modern athlete.",
    "category": "Wearables",
    "tags": ["smartwatch", "fitness", "heart-rate", "gps"],
    "feature_image": "https://via.placeholder.com/150",
    "alternate_images": [
      "https://via.placeholder.com/150",
      "https://via.placeholder.com/150"
    ],
    "focus_keyphrase": "smart fitness watch",
    "slug": "smart-fitness-watch",
    "meta_description": "Stay fit and connected with the best smart fitness watch featuring heart rate monitoring and GPS.",
    "vof_landing_page_sections": [
      {
        "section": "overview",
        "content": "This smart fitness watch helps you track your activity, monitor your heart rate, and stay connected with GPS and smartphone notifications.",
        "image": "https://via.placeholder.com/150"
      },
      {
        "section": "features",
        "content": "Heart rate monitoring, GPS, waterproof, smartphone notifications, 7-day battery life.",
        "image": "https://via.placeholder.com/150"
      },
      {
        "section": "specifications",
        "content": "Battery life: 7 days, Water resistance: 50 meters, Display size: 1.3 inches, GPS: Built-in, Weight: 40g.",
        "image": "https://via.placeholder.com/150"
      }
    ],
    "brand": "FitTrack",
    "stock_status": "in_stock",
    "weight": "40g",
    "dimensions": "4 x 4 x 1 cm",
    "variations": [
      {
        "sku": "SFW-001",
        "name": "Variant 1",
        "image": 'https://via.placeholder.com/150',
        "regular_price": 180,
        "discount_price": 149,
        "stock_status": "in_stock"
      },
      {
        "sku": "SFW-002",
        "name": "Variant 2",
        "image": 'https://via.placeholder.com/150',
        "regular_price": 200,
        "discount_price": 169,
        "stock_status": "in_stock"
      }
    ]
  },
  {
    "title": "Curren 8314 Watch",
    "description": "Track your fitness levels, monitor your heart rate, and stay connected with this sleek smart fitness watch designed for the modern athlete.",
    "category": "Wearables",
    "tags": ["smartwatch", "fitness", "heart-rate", "gps"],
    "feature_image": "https://via.placeholder.com/150",
    "alternate_images": [
      "https://via.placeholder.com/150",
      "https://via.placeholder.com/150"
    ],
    "focus_keyphrase": "smart fitness watch",
    "slug": "curren-8314-watch",
    "meta_description": "Stay fit and connected with the best smart fitness watch featuring heart rate monitoring and GPS.",
    "vof_landing_page_sections": [
      {
        "section": "overview",
        "content": "This smart fitness watch helps you track your activity, monitor your heart rate, and stay connected with GPS and smartphone notifications.",
        "image": "https://via.placeholder.com/150"
      },
      {
        "section": "features",
        "content": "Heart rate monitoring, GPS, waterproof, smartphone notifications, 7-day battery life.",
        "image": "https://via.placeholder.com/150"
      },
      {
        "section": "specifications",
        "content": "Battery life: 7 days, Water resistance: 50 meters, Display size: 1.3 inches, GPS: Built-in, Weight: 40g.",
        "image": "https://via.placeholder.com/150"
      }
    ],
    "brand": "FitTrack",
    "stock_status": "in_stock",
    "weight": "40g",
    "dimensions": "4 x 4 x 1 cm",
    "variations": [
      {
        "sku": "SFW-001",
        "name": "Variant 1",
        "image": 'https://via.placeholder.com/150',
        "regular_price": 180,
        "discount_price": 149,
        "stock_status": "in_stock"
      },
      {
        "sku": "SFW-002",
        "name": "Variant 2",
        "image": 'https://via.placeholder.com/150',
        "regular_price": 200,
        "discount_price": 169,
        "stock_status": "in_stock"
      }
    ]
  },
  {
    "title": "Skmei 1888 Watch",
    "description": "Track your fitness levels, monitor your heart rate, and stay connected with this sleek smart fitness watch designed for the modern athlete.",
    "category": "Wearables",
    "tags": ["smartwatch", "fitness", "heart-rate", "gps"],
    "feature_image": "https://via.placeholder.com/150",
    "alternate_images": [
      "https://via.placeholder.com/150",
      "https://via.placeholder.com/150"
    ],
    "focus_keyphrase": "smart fitness watch",
    "slug": "skmei-1888-watch",
    "meta_description": "Stay fit and connected with the best smart fitness watch featuring heart rate monitoring and GPS.",
    "vof_landing_page_sections": [
      {
        "section": "overview",
        "content": "This smart fitness watch helps you track your activity, monitor your heart rate, and stay connected with GPS and smartphone notifications.",
        "image": "https://via.placeholder.com/150"
      },
      {
        "section": "features",
        "content": "Heart rate monitoring, GPS, waterproof, smartphone notifications, 7-day battery life.",
        "image": "https://via.placeholder.com/150"
      },
      {
        "section": "specifications",
        "content": "Battery life: 7 days, Water resistance: 50 meters, Display size: 1.3 inches, GPS: Built-in, Weight: 40g.",
        "image": "https://via.placeholder.com/150"
      }
    ],
    "brand": "FitTrack",
    "stock_status": "in_stock",
    "weight": "40g",
    "dimensions": "4 x 4 x 1 cm",
    "variations": [
      {
        "sku": "SFW-001",
        "name": "Variant 1",
        "image": 'https://via.placeholder.com/150',
        "regular_price": 180,
        "discount_price": 149,
        "stock_status": "in_stock"
      },
      {
        "sku": "SFW-002",
        "name": "Variant 2",
        "image": 'https://via.placeholder.com/150',
        "regular_price": 200,
        "discount_price": 169,
        "stock_status": "in_stock"
      }
    ]
  }
]

// Dummy Data for Districts and Thanas
export const districtsData = {
  "Dhaka": ["Dhanmondi", "Gulshan", "Mirpur", "Uttara", "Tejgaon", "Mohammadpur"],
  "Chittagong": ["Pahartali", "Panchlaish", "Double Mooring", "Bhakurta", "Sandwip", "Sitakunda"],
  "Sylhet": ["Sylhet Sadar", "Beanibazar", "Zakiganj", "Jaintiapur", "Gowainghat", "Companiganj"],
  "Rajshahi": ["Rajshahi Sadar", "Puthia", "Bagmara", "Tanore", "Durgapur"],
  "Khulna": ["Khulna Sadar", "Sonadanga", "Dighalia", "Batiaghata", "Rupsha"],
  "Barisal": ["Barisal Sadar", "Banaripara", "Gournadi", "Mehendiganj", "Muladi"],
  "Rangpur": ["Rangpur Sadar", "Badarganj", "Karnapur", "Pirganj", "Gangachara"],
  "Mymensingh": ["Mymensingh Sadar", "Trishal", "Gafargaon", "Muktagachha", "Phulpur"]
};
